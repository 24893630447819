window.addEventListener('load', () => {
    document.querySelector('video').play();
});

window.addEventListener('click', () => {
    document.querySelector('video').play();
});

function autoPlayAudio1() {
    wx.config({
        // 配置信息, 即使不正确也能使用 wx.ready
        debug: false,
        appId: '',
        timestamp: 1,
        nonceStr: '',
        signature: '',
        jsApiList: [],
    });
    wx.ready(function () {
        document.querySelector('video').play();
    });
}

// 首页元素初始化动画
window.addEventListener('load', function () {
    initAnimation();
});

function initAnimation() {
    let elements = document.querySelectorAll('.animation');
    elements = Array.prototype.slice.call(elements);
    elements.forEach(element => {
        element.style.transition = `all ${300 + Math.random() * 1000}ms`;
    });
    elements.forEach(elementAnimation);
    window.addEventListener('scroll', function () {
        elements.forEach(elementAnimation);
    });
}

function elementAnimation(element) {
    const clientHeight = document.documentElement.clientHeight;
    const { top } = element.getBoundingClientRect();
    let calssNames = element.className.split(' ');
    if (top < clientHeight - 50 && ~calssNames.indexOf('inplace')) {
        element.className = calssNames
            .filter(name => name != 'inplace')
            .join(' ');
    } else if (top >= clientHeight - 50 && !~calssNames.indexOf('inplace')) {
        element.className = element.className + ' inplace';
    }
}

// 首屏下滑动画
let ease = 1;

function step() {
    console.log('step');
    const height = window.innerHeight;
    ease += 0.1;
    if (window.scrollY + 8 * ease < height) {
        window.scrollTo(0, window.scrollY + 8 * ease);
        window.requestAnimationFrame(step);
    } else if (window.scrollY < height) {
        window.scrollTo(0, height);
        ease = 1;
    } else {
        ease = 1;
    }
}

document.querySelector('.index-scroll').addEventListener('click', step);
